<template>
    <Page :title="pageTitle" :icon="pageIcon">
        <slot name="before-form" />
        <smart-form
            v-if="formModel && formSchema"
            :schema="formSchema"
            v-model="formModel"
            @input="formUpdated"
            v-bind="$attrs"
            :key="`smartform${useReloadTrigger}`"

        />
        <template v-slot:header>
            <toolbar
                :left-items="[
                    { id: 'save', preset: 'save', onClick: saveFormData, size: 'small' },
                    ...(buttonsLeftItems || []),
                    {
                        id: 'done',
                        preset: 'done',
                        onClick: doneRedirect,
                        size: 'small',
                    },
                ]"
                :right-items="buttonsRightItems"
                :valid-button-ids="validButtonIds"
                :buttonFunctions="buttonFunctions"
                :key="`${JSON.stringify(buttonsLeftItems)}${JSON.stringify(buttonsRightItems)}`"
            />
        </template>
        <slot name="after-form" />
    </Page>
</template>
<script>
import api from '@/api';

export default {
    name: 'Maintenance',
    props: {
        value: Object,
        shadowValueString: String, // used to trigger updated if formValue updated from calling component

        pageTitle: String, // eg 'Account'
        pageIcon: String, // eg 'mdi-folder-outline'
        formCode: String,

        collectionName: String, // eg 'account'
        module: { type: String, default: 'admin' } /* eg admin bill accounts property */,

        buttonFunctions: Object,

        buttonsLeftItems: Array,
        buttonsRightItems: Array,

        baseUrl: String,
        doneUrl: String,

        reloadTrigger: Number,
        showFooter: Boolean,
    },
    data() {
        return {
            formModel: null,
            formSchema: null,
            validButtonIds: null,
            dataSets: null,
            id: null,
            useReloadTrigger: null,
        };
    },
    methods: {
        formUpdated(val) {
            this.$emit('input', val);
        },
        getUrl() {
            if (this.baseUrl) {
                return this.baseUrl;
            }

            return this.collectionName.toLowerCase();
        },
        getEntityCode() {
            return this.collectionName.toLowerCase();
        },
        // setKey() {
        //     this.key = `${JSON.stringify(this.buttonsLeftItems)} ${JSON.stringify(this.buttonsRightItems)}`;
        // },
        async getFormData() {
            const schemaResult = await api.get(this.$store, `form/schema/${this.getEntityCode()}/maint`);
            if (!schemaResult || !schemaResult.data) return;

            const { formSchema, validButtonIds } = schemaResult.data;

            this.validButtonIds = validButtonIds;
            this.formSchema = formSchema;

            if (this.value) {
                this.formModel = this.value;
            } else {
                const result = await api.get(this.$store, `${this.getUrl()}/${this.id}`);
                this.formModel = api.objectToFormData(this.formSchema, { ...result.data });
            }
            // this.setKey();
        },
        async saveFormData() {
            const result = await api.post(this.$store, `${this.getUrl()}`, api.formDataToObject(this.formModel));

            if (result.data && result.data.success) {
                this.formModel = result.data[this.collectionName];
                // this.setKey();
                await this.$store.dispatch('setSuccess', 'Saved');
            }
            this.$emit('input', this.formModel);
            this.$emit('save', this.formModel);
        },
        doneRedirect() {
            const url = this.doneUrl || this.getUrl();

            const doneRedirectUrl = `/auth/${this.module}/${url}?isHistory=true`;

            this.$router.push(doneRedirectUrl);
        },
        async init() {
            this.id = this.$route.params.id;
            await this.getFormData();
            this.$emit('input', this.formModel);
        },
    },
    watch: {
        async reloadTrigger() {
            console.log('List Page - reloadTrigger', this.reloadTrigger);
            await this.getFormData();
            this.useReloadTrigger = this.reloadTrigger;
        },
    },
    async created() {
        await this.init();
    },
};
</script>
