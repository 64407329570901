var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":_vm.pageTitle,"icon":_vm.pageIcon},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('toolbar',{key:`${JSON.stringify(_vm.buttonsLeftItems)}${JSON.stringify(_vm.buttonsRightItems)}`,attrs:{"left-items":[
                { id: 'save', preset: 'save', onClick: _vm.saveFormData, size: 'small' },
                ...(_vm.buttonsLeftItems || []),
                {
                    id: 'done',
                    preset: 'done',
                    onClick: _vm.doneRedirect,
                    size: 'small',
                },
            ],"right-items":_vm.buttonsRightItems,"valid-button-ids":_vm.validButtonIds,"buttonFunctions":_vm.buttonFunctions}})]},proxy:true}])},[_vm._t("before-form"),(_vm.formModel && _vm.formSchema)?_c('smart-form',_vm._b({key:`smartform${_vm.useReloadTrigger}`,attrs:{"schema":_vm.formSchema},on:{"input":_vm.formUpdated},model:{value:(_vm.formModel),callback:function ($$v) {_vm.formModel=$$v},expression:"formModel"}},'smart-form',_vm.$attrs,false)):_vm._e(),_vm._t("after-form")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }