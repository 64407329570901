<template>
    <maintenance-page
        v-if="isDefaultExpenseBank !== null"
        page-title="Bank"
        page-icon="mdi-account-multiple"
        collection-name="bank"
        done-redirect-url="/auth/admin/bank?isHistory=true"
        :buttonFunctions="{ setDefaultExpenseBank }"
        :buttonsLeftItems="buttonsLeftItems"
    >
        <template v-slot:before-form>
            <div class="mx-7">
                <v-chip v-if="isDefaultExpenseBank" class="ma-3 pa-3 primary">{{ defaultExpenseBankMessage }}</v-chip>
            </div>
        </template>
    </maintenance-page>
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';
import api from '@/api';

const buttonsLeftItems = [
    {
        id: 'setDefaultExpenseBank',
        preset: 'edit',
        label: 'Set as Default Expense',
        size: 'small',
        clickFunctionName: 'setDefaultExpenseBank',
    },
];

export default {
    name: 'BankDetail',
    components: { MaintenancePage },
    data: () => ({
        buttonsLeftItems,
        isDefaultExpenseBank: null,
        defaultExpenseBankMessage: '',
    }),
    methods: {
        async setDefaultExpenseBank(formModel) {
            const result = await api.post(this.$store, `bank/companyexpensebank`, api.formDataToObject(formModel));

            // console.log('result from post:bank/setDefaultExpenseAccount');
            // console.log(result.data);
            await this.getDefaultExpenseAccount(formModel.id);
            return { buttonsLeftItems: this.buttonsLeftItems };
        },
        async getDefaultExpenseAccount(bankId) {
            const result = await api.get(this.$store, `bank/companyexpensebank/${bankId}`);

            if (result.data) {
                const { isDefaultExpenseBank, companyCode } = result.data;
                this.isDefaultExpenseBank = isDefaultExpenseBank;
                this.defaultExpenseBankMessage = `Default expense bank for ${companyCode}`;
                if (this.isDefaultExpenseBank) {
                    this.buttonsLeftItems = [];
                }
            } else {
                this.isDefaultExpenseBank = false;
            }
        },
        async init(bankId) {
            await this.getDefaultExpenseAccount(bankId);
        },
    },
    updated() {},
    async mounted() {
        const bankId = this.$route.params.id;
        await this.init(bankId);
    },
};
</script>
